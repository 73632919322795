import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useState, useEffect } from 'react'
import { useTheme } from 'styled-components/native'
import { Button, Card, PageTitle, Spacer, Typography } from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import Loader from '../../../components/Loader'
import { DeliveryNote } from '../../../domain'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import { ToursContentWrapper, StyledButtonWrapper } from './CarrierTourDeliveryNoteSendMail.styles'
import { MAIL_TYPES, MailEnums } from '../../../../enums/mail'
import DeliveryNoteCarrierService from '../../../services/carrier/deliveryNote'
import { DeliveryNoteTypeEnum } from '../../../../enums'
import DeliveryNoteMailHistory from '../../../modules/DeliveryNote/DeliveryNoteMailHistory'

const getAppropriateEmail = (deliveryNote: DeliveryNote | undefined): string => {
  if (!deliveryNote) {
    return ''
  }

  if (
    deliveryNote.type === DeliveryNoteTypeEnum.PRODUCER_CLIENT ||
    deliveryNote.type === DeliveryNoteTypeEnum.CARRIER_CLIENT
  ) {
    const clientEmail = deliveryNote.order?.cart?.client?.account?.email || ''
    return clientEmail
  }

  if (deliveryNote.type === DeliveryNoteTypeEnum.PRODUCER_CARRIER) {
    const producerEmail = deliveryNote.producer?.account?.email || ''
    return producerEmail
  }

  return ''
}

const CarrierTourDeliveryNoteSendMailScreen = () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTourDeliveryNoteSendMail'>>()
  const { deliveryNoteId } = route?.params ?? { deliveryNoteId: '' }
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [selectMail, setSelectedMail] = useState<string>('')

  const theme = useTheme()

  const {
    data: deliveryNote,
    refetch: refetchDeliveryNote,
    isInitialLoading: deliveryNoteLoading,
  } = useQuery<DeliveryNote, Error>(
    ['sf_tour_delivery_note', deliveryNoteId],
    () => DeliveryNoteCarrierService.getOne(deliveryNoteId),
    {
      keepPreviousData: true,
    },
  )

  // Set the initial email based on deliveryNote data
  useEffect(() => {
    const emailAddress = getAppropriateEmail(deliveryNote)
    setSelectedMail(emailAddress)
  }, [deliveryNote])

  if (deliveryNoteLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!deliveryNote) return null

  const onSendMail = async () => {
    setErrorMessage('')
    setIsLoading(true)

    const dto = {
      mails: selectMail ? [selectMail] : undefined,
    }

    const sendedMailDeliveryNote = await DeliveryNoteCarrierService.sendMail(deliveryNoteId, dto)
    if (sendedMailDeliveryNote && sendedMailDeliveryNote.id) {
      await queryClient.invalidateQueries({ queryKey: ['sf_tour_delivery_note', deliveryNoteId] })

      navigation.navigate('CarrierTourDeliveryNote', { deliveryNoteId: deliveryNoteId })
    } else {
      setErrorMessage(`Un problème est survenu lors de l'envoie du mail pour ce BL`)
    }
    setIsLoading(false)
  }

  const mailSent = (mailType: MailEnums): boolean => {
    let isSent = false
    const history = deliveryNote.mailsStatus?.history
    if (history && history.length) {
      const foundHistory = history.find((h) => h.type === mailType)
      isSent = foundHistory !== null
    }

    return isSent
  }

  const mappedMailType = (): MailEnums => {
    let mappedMailType = MailEnums.DELIVERY_NOTE_PRODUCER_CARRIER
    if (deliveryNote.type === DeliveryNoteTypeEnum.PRODUCER_CLIENT) {
      mappedMailType = MailEnums.DELIVERY_NOTE_PRODUCER_CLIENT
    }
    if (deliveryNote.type === DeliveryNoteTypeEnum.CARRIER_CLIENT) {
      mappedMailType = MailEnums.DELIVERY_NOTE_CARRIER_CLIENT
    }
    return mappedMailType
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title={`Envoie d'un BL par mail`} />

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ScrollableFormWrapper>
            <Card>
              <Typography.Body>Type d'email</Typography.Body>
              <Typography.CardTitle>
                {
                  MAIL_TYPES.find(
                    (mailType) => mailType.value === MailEnums.DELIVERY_NOTE_PRODUCER_CARRIER,
                  )?.label
                }
              </Typography.CardTitle>
            </Card>
            <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
            <DeliveryNoteMailHistory deliveryNote={deliveryNote} />
            <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.Body bold>Envoyer à : </Typography.Body>
            <Spacer size={0.6} axis={Spacer.AxisEnum.VERTICAL} />
            <TextInput
              value={selectMail}
              label="Email"
              field="text"
              onChangeText={(text) => setSelectedMail(text)}
              autoFocus
            />
            <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.Body bold>Ce mail sera envoyé à: </Typography.Body>
            <Typography.Body>{selectMail || 'Aucun email sélectionné'}</Typography.Body>
          </ScrollableFormWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <StyledButtonWrapper>
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            {mailSent(mappedMailType()) && (
              <>
                <Typography.Body colorName="color-danger">
                  Ce mail à déjà été envoyé une fois
                </Typography.Body>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              </>
            )}
            <Button
              label={mailSent(mappedMailType()) ? 'Renvoyer e-mail(s)' : 'Envoyer e-mail(s)'}
              onPress={() => onSendMail()}
              loading={isLoading}
              colorName={mailSent(mappedMailType()) ? 'color-danger' : 'color-secondary'}
            />
          </StyledButtonWrapper>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierTourDeliveryNoteSendMailScreen
