import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { ScrollView } from 'react-native'
import { useTheme } from 'styled-components/native'
import { Button, PageTitle, SectionTitle, Spacer } from '../../../components'
import CardList from '../../../components/CardList'
import { Cart, Order, Pagination } from '../../../domain'
import CartCard from '../../../modules/Cart/CartCard'
import OrderCard from '../../../modules/Order/OrderCard'
import { ClientStackParamList } from '../../../navigation/ClientNavigationStack/ClientNavigationStack.model'
import CartClientService from '../../../services/client/cart'
import OrderClientService from '../../../services/client/order'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import StorageUtil from '../../../utilities/storage/storage'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { StyledButtonWrapper } from './ClientDashboard.styles'
import HelpFooter from '../../../components/HelpFooter'
import DashboardStats from '../../../components/DashboardStats'
import MetricsClientService, { ClientDashboardMetrics } from '../../../services/client/metrics'
import AccountService from '../../../services/billing/account'
import ActionCard from '../../../modules/Common/ActionCard'

const currentMonth = new Date().getMonth()
const currentYear = new Date().getFullYear()
const defaultDateHour = new Date(currentYear, currentMonth, 1, 0, 0, 0)
const defaultDateHour2 = new Date(currentYear, currentMonth + 1, 1, 0, 0, 0)

const ClientDashboardScreen = () => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<ClientStackParamList>>()
  const { getAccessInfos } = useAuthenticationContext()
  const [metricsFilters, setMetricsFilters] = useState({
    start: new Date(defaultDateHour),
    end: new Date(defaultDateHour2),
  })

  const {
    data: carts,
    refetch: refetchCarts,
    isInitialLoading: cartsLoading,
  } = useQuery<Cart[], Error>(
    ['c_carts'],
    () => CartClientService.getAllCarts(getAccessInfos().clientId),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: orders,
    refetch: refetchOrders,
    isInitialLoading: ordersLoading,
  } = useQuery<{ data: Order[]; pagination: Pagination }, Error>(
    ['c_orders'],
    () => OrderClientService.getAllOrders(getAccessInfos().clientId, true, false, 50),
    {
      keepPreviousData: true,
    },
  )

  // Filter out carts that have corresponding orders for the same tour
  const filteredCarts = React.useMemo(() => {
    if (!carts || !orders?.data) return []

    return carts.filter((cart) => {
      const cartTourId = cart.tour?.id

      // Check if there's any order with the same tour ID
      const hasMatchingOrder = orders.data.some((order) => {
        const orderTourId = order.cart?.tour?.id
        return orderTourId === cartTourId
      })

      // Keep the cart only if there's no matching order
      return !hasMatchingOrder
    })
  }, [carts, orders?.data])

  const {
    data: metrics,
    refetch: refetchMetrics,
    isInitialLoading: metricsLoading,
  } = useQuery<ClientDashboardMetrics, Error>(
    ['c_metrics_dashboard', getAccessInfos().clientId],
    () =>
      MetricsClientService.getDashboardMetrics(
        getAccessInfos().clientId,
        metricsFilters.start,
        metricsFilters.end,
      ),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: paymentMethods,
    refetch: refetchPaymentMethods,
    isInitialLoading: paymentMethodsLoading,
  } = useQuery<{ count: number; stripeCustomerId: string }, Error>(
    ['c_metrics_dashboard', getAccessInfos().accountId],
    () => AccountService.getPaymentMethods(getAccessInfos().accountId),
    {
      keepPreviousData: true,
      enabled: getAccessInfos()?.accountId !== null,
      staleTime: 10000,
    },
  )

  const OnClickNewOrder = async () => {
    const cartId = await StorageUtil.getItem('c_cart_id')
    if (cartId) {
      await queryClient.setQueryData(['c_cart', cartId], null)
      await queryClient.removeQueries(['c_cart', cartId])
      await queryClient.invalidateQueries(['c_cart', cartId])
    }

    await StorageUtil.deleteItem('c_cart_id')
    await queryClient.setQueryData(['c_catalog'], undefined)
    await queryClient.invalidateQueries(['c_catalog'])

    navigation.navigate('ClientCatalog', {})
  }

  const OnClickOrder = (order: Order) => {
    navigation.navigate('ClientOrders', {
      screen: 'ClientOrder',
      params: { id: order.id },
    })
  }

  const OnClickCart = async (cart: Cart) => {
    await StorageUtil.setItem('c_cart_id', cart.id)

    navigation.navigate('ClientCatalog', {
      screen: 'ClientCatalog',
      params: {},
    })
  }

  useEffect(() => {
    refetchMetrics()
  }, [metricsFilters])

  const onClickMetricsChangeDate = async (metricsFilters: { start: Date; end: Date }) => {
    let currentMonthNew = metricsFilters.start.getMonth()
    let currentYear = metricsFilters.start.getFullYear()

    if (currentMonthNew < 0) {
      currentMonthNew = 11
      currentYear = currentYear - 1
    }

    if (currentMonthNew > 11) {
      currentMonthNew = 0
      currentYear = currentYear + 1
    }

    const newStartDate = new Date(currentYear, currentMonthNew, 1, 0, 0, 0)

    const newEndDate = new Date(currentYear, currentMonthNew + 1, 1, 0, 0, 0)

    const tempMetricsFilter = { start: newStartDate, end: newEndDate }

    await setMetricsFilters(tempMetricsFilter)
  }

  const clientMetrics = [
    {
      label: 'Producteurs',
      value: metrics?.nbProducers ? metrics?.nbProducers.toString() : '-',
    },
    {
      label: 'Produits disponibles',
      value: metrics?.nbActualProducts ? metrics?.nbActualProducts.toString() : '-',
    },
    { label: 'Total produits', value: metrics?.nbProducts ? metrics?.nbProducts.toString() : '-' },
  ]

  const onPressBillingPortal = async () => {
    const billingPageURL = await await AccountService.getBillingPortalURL(
      getAccessInfos().accountId,
    )

    if (billingPageURL) {
      window.open(billingPageURL, '_blank')
    }
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Accueil" />
        <DashboardStats
          metrics={clientMetrics}
          metricsFilters={metricsFilters}
          isLoading={metricsLoading}
          metricsType="MONTH"
          onChangeDates={onClickMetricsChangeDate}
          hideDateSwitcher
        />
        {!paymentMethodsLoading &&
        paymentMethods &&
        paymentMethods.stripeCustomerId &&
        paymentMethods.count === 0 ? (
          <ActionCard
            title={'Moyen de paiement manquant'}
            description={'Veuillez renseigner votre moyen de paiement'}
            onClick={onPressBillingPortal}
            key={1}
          />
        ) : undefined}
        <SectionTitle title={`Passer une commande`} />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <StyledButtonWrapper>
          <Button small label="Nouvelle Commande" onPress={() => OnClickNewOrder()} />
        </StyledButtonWrapper>

        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />

        {filteredCarts && filteredCarts.length > 0 ? (
          <>
            <SectionTitle title={`Reprendre un panier`} />
            <CardList
              data={filteredCarts}
              element={function (cart: any) {
                return <CartCard key={cart.id} cart={cart} onClick={OnClickCart} />
              }}
              isLoading={cartsLoading}
              emptyMessage="Aucun panier disponible"
            />
          </>
        ) : null}

        {orders?.data && orders?.data.length > 0 ? (
          <>
            <SectionTitle title={`Commandes à venir`} />
            <CardList
              data={orders?.data}
              element={function (order: any) {
                return <OrderCard key={order.id} order={order} onClick={OnClickOrder} />
              }}
              isLoading={ordersLoading}
              emptyMessage="Aucune commande à venir"
            />
          </>
        ) : null}
        <HelpFooter />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ClientDashboardScreen
