import { useCallback } from 'react'
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native'
import { startOfMonth, endOfMonth, addMonths, format, parse, isValid } from 'date-fns'
import { StackNavigationProp } from '@react-navigation/stack'
import { CarrierStackParamList } from '../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { FilterParams } from '../../domain'

export default () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierBillingDeliveryNotes'>>()

  const filters: FilterParams = {
    producer: route.params?.producer,
    client: route.params?.client,
    start: route.params?.start,
    end: route.params?.end,
    page: route.params?.page ? Number(route.params.page) : 1,
    pageSize: route.params?.pageSize ? Number(route.params.pageSize) : 10,
    mode: route.params?.mode ? Number(route.params.mode) : 1,
  }

  const setFilters = useCallback(
    (newFilters: Partial<FilterParams>) => {
      const currentParams = route.params || {}
      const updatedFilters = {
        ...currentParams,
        ...newFilters,
      }
      const cleanFilters = Object.fromEntries(
        Object.entries(updatedFilters).filter(([_, value]) => value !== undefined),
      )
      navigation.setParams(cleanFilters)
    },
    [navigation, route.params],
  )

  const setProducer = useCallback(
    (producerId: string | undefined) => {
      setFilters({
        producer: producerId,
        page: 1,
      })
    },
    [setFilters],
  )

  const setClient = useCallback(
    (clientId: string | undefined) => {
      setFilters({
        client: clientId,
        page: 1,
      })
    },
    [setFilters],
  )

  const setPage = useCallback(
    (pageNumber: number) => {
      setFilters({ page: pageNumber })
    },
    [setFilters],
  )

  const setMode = useCallback(
    (mode: number) => {
      setFilters({
        mode: mode === 1 || mode === 2 ? mode : 1,
        page: 1,
      })
    },
    [setFilters],
  )

  const setDates = useCallback(
    (monthOffset?: number, exactDate?: Date, dateType?: 'startDate' | 'endDate') => {
      if (exactDate && dateType && isValid(exactDate)) {
        setFilters({
          [dateType]: format(exactDate, 'yyyy-MM-dd'),
          page: 1,
        })
      } else if (monthOffset !== undefined) {
        const currentStartDate = filters.start
          ? parse(filters.start, 'yyyy-MM-dd', new Date())
          : new Date()

        const newDate = addMonths(currentStartDate, monthOffset)
        const newStartDate = startOfMonth(newDate)
        const newEndDate = endOfMonth(newDate)

        setFilters({
          start: format(newStartDate, 'yyyy-MM-dd'),
          end: format(newEndDate, 'yyyy-MM-dd'),
          page: 1,
        })
      }
    },
    [setFilters, filters.start],
  )

  const getDates = useCallback(() => {
    return {
      start: filters.start
        ? parse(filters.start, 'yyyy-MM-dd', new Date())
        : startOfMonth(new Date()),
      end: filters.end ? parse(filters.end, 'yyyy-MM-dd', new Date()) : endOfMonth(new Date()),
    }
  }, [filters.start, filters.end])

  const resetClientProducerFilters = useCallback(() => {
    const { ...restParams } = route.params || {}

    navigation.setParams({
      ...restParams,
      client: undefined,
      producer: undefined,
      page: 1,
    })
  }, [navigation, route.params])

  return {
    filters,
    setFilters,
    resetClientProducerFilters,
    setDates,
    getDates,
    setPage,
    setMode,
    setClient,
    setProducer,
  }
}
