import { InvoiceStatusEnum } from '../../../enums/invoice'
import { PaymentMethodEnum } from '../../../enums/payment'
import { ParentLineWithChildren } from '../../components/InvoiceLine/InvoiceLine.model'
import { Account } from '../Account'
import { CarrierFeesRecord } from '../CarrierFees'
import { DeliveryNote } from '../DeliveryNote'
import { Payment } from '../Payment'
import { TotalAmounts } from '../TotalAmounts'

export enum InvoiceType {
  TRADE = 1, // Regular invoice between client and producer
  PLATFORM_FEE = 2, // Platform commission fees
  CARRIER_FEE = 4, // Delivery service fees, generated during the automatic platformBilling process
  CARRIER_FEE_DIY = 8, // Delivery service fees created manually by carrier for their custom handled billing (not platform billing)
}

export type Invoice = {
  id: string
  identifier: number
  displayIdentifier: string
  totalAmounts: TotalAmounts
  paymentMethod: PaymentMethodEnum
  status: InvoiceStatusEnum
  issuer: Account
  recipient: Account
  carrierFeesRecords: CarrierFeesRecord[]
  deliveryNotes: DeliveryNote[]
  payment: Payment
  customIdentifier?: string
  createdDate: Date
  dueDate: Date
  lines: ParentLineWithChildren[]
  type: InvoiceType
}

export type UpdateInvoice = {
  status: InvoiceStatusEnum
}
