import { useState } from 'react'
import useFilterParams from './useFilterParams'

export default function useFilterBar() {
  const { filters, resetClientProducerFilters, setClient, setProducer } = useFilterParams()

  const [displayFilters, setDisplayFilters] = useState(!!(filters.client || filters.producer))

  const handleToggleFilters = () => {
    setDisplayFilters(!displayFilters)
  }

  const handleChangeProducer = (producer: any) => {
    setProducer(producer.id)
  }

  const handleChangeClient = (client: any) => {
    setClient(client.id)
  }

  const handleResetFilters = () => {
    resetClientProducerFilters()
    setDisplayFilters(false)
  }

  const handleApplyFilters = () => {
    setDisplayFilters(false)
  }

  return {
    displayFilters,
    handleToggleFilters,
    handleChangeProducer,
    handleChangeClient,
    handleResetFilters,
    handleApplyFilters,
  }
}
