import styled from 'styled-components/native'

const TotalSummaryCardWrapper = styled.View`
  width: 100%;
  justify-content: center;
  align-items: center;
`
const TotalSummaryCardContent = styled.View`
  background-color: ${({ theme }) => theme.colors['color-quaternary']};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  padding: ${({ theme }) => theme.spacingUnit * 1.5}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 4}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 4}px;
  width: fit-content;
  flex-direction: row;
  justify-content: space-between;
`

const SummaryLeftBox = styled.View`
  max-width: 207px;
  overflow: hidden;
`
const SummaryRightBox = styled.View`
  align-items: flex-end;
`
const SummaryMiddleBox = styled.View`
  margin-top: ${({ theme }) => theme.spacingUnit * 6.3}px;
`

export {
  TotalSummaryCardWrapper,
  TotalSummaryCardContent,
  SummaryLeftBox,
  SummaryRightBox,
  SummaryMiddleBox,
}
