import React from 'react'
import { View } from 'react-native'
import { PRODUCER_BILLING_TYPES } from '../../../../../../enums/producer'
import { Dropdown, Spacer, Typography, Button } from '../../../../../components'
import { FilterBarProps } from '../../../../../domain/Pagination'

export const FilterButton = ({ onPress }: { onPress: () => void }) => (
  <Button small label="Filtres" onPress={onPress} />
)

const FilterBar = ({
  clientGroups,
  producerGroups,
  selectedClient,
  selectedProducer,
  displayFilters,
  onChangeProducer,
  onChangeClient,
  onResetFilters,
  onApplyFilters,
}: FilterBarProps) => {
  if (!displayFilters && (selectedClient || selectedProducer)) {
    return (
      <>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <Typography.Body colorName="text-dark-3">
          Filtres : {selectedProducer ? `producteur (${selectedProducer.label})` : ''}{' '}
          {selectedClient ? `client (${selectedClient.label})` : ''}
        </Typography.Body>
      </>
    )
  }

  if (!displayFilters) {
    return null
  }

  return (
    <>
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
      <Typography.Body colorName="text-dark-3">Filtres</Typography.Body>
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

      <Dropdown
        label="Choisir un producteur"
        onChange={onChangeProducer}
        multiple={false}
        defaultValue={selectedProducer?.id}
        optionsDefault={(producerGroups || []).map((producerGroup) => ({
          ...producerGroup.producer,
          label: `${producerGroup.producer?.label} (facturation ${
            PRODUCER_BILLING_TYPES.find((bt) => bt.value === producerGroup?.billingType)?.label
          })`,
          value: producerGroup.producer?.id,
        }))}
        zIndex={2000}
        itemKey="producers"
        isSearchable
      />

      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

      <Dropdown
        label="Choisir un client"
        onChange={onChangeClient}
        multiple={false}
        defaultValue={selectedClient?.id}
        optionsDefault={(clientGroups || []).map((clientGroup) => ({
          ...clientGroup.client,
          label: clientGroup.client?.label,
          value: clientGroup.client?.id,
        }))}
        zIndex={1000}
        itemKey="clients"
        isSearchable
      />

      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

      <View style={{ flexDirection: 'row' }}>
        <Button small colorName="color-danger" label="Supprimer filtres" onPress={onResetFilters} />
        <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={1} />
        <Button small label="Valider" onPress={onApplyFilters} />
      </View>
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
    </>
  )
}

export default FilterBar
