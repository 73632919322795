import React from 'react'
import { useTheme } from 'styled-components/native'
import Loader from '../Loader'
import Spacer from '../Spacer'
import Typography from '../Typography'
import { CardListPaginatedProps } from './CardListPaginated.model'
import { StyledList, Styledloading } from './CardListPaginated.styles'
import PaginationBar from '../PaginationBar'

function CardListPaginated({
  data,
  pagination,
  element,
  isLoading,
  emptyMessage,
  onChangePage,
  hidePagination,
}: CardListPaginatedProps) {
  const theme = useTheme()

  if (isLoading && (!data || data.length === 0)) {
    return (
      <StyledList>
        <Loader isLoading withText />
      </StyledList>
    )
  }

  if (!isLoading && (!data || data.length < 1)) {
    return (
      <StyledList>
        <Styledloading>
          <Typography.BodySmall colorName="text-dark-3">{emptyMessage}</Typography.BodySmall>
        </Styledloading>
      </StyledList>
    )
  }

  return (
    <StyledList>
      {data?.map(element)}
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
      {!hidePagination && (
        <PaginationBar data={data} pagination={pagination} onChangePage={onChangePage} />
      )}
    </StyledList>
  )
}

export default CardListPaginated
