import React from 'react'
import { Switch, View } from 'react-native'
import { useTheme } from 'styled-components/native'
import DirectusUtil from '../../utilities/utils/directus'
import { Product } from '../../domain'
import Typography from '../Typography'
import TouchableCard from '../TouchableCard'
import { ShelfLabelSettingCardProps } from './ShelfLabelSettingCard.model'
import {
  StyledCardWrapper,
  StyledCardContent,
  StyledLeftBox,
  StyledImage,
  StyledCenterBox,
  StyledValuesWrapper,
  CardContentWrapper,
} from './ShelfLabelSettingCard.styles'
import { ShelfLabelSizeEnum } from '../../domain/ShelfLabel'

const ShelfLabelSettingCard = ({
  shelfLabel,
  shelfLabelSize,
  onChangeShelfLabelSize,
}: ShelfLabelSettingCardProps) => {
  const theme = useTheme()
  return (
    <StyledCardWrapper activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <StyledImage
              source={DirectusUtil.getProductImageUrlFromId({
                imageId: shelfLabel.cartBatch?.batch?.product?.imageId,
              } as any as Product)}
              resizeMode="cover"
            />
          </StyledLeftBox>
          <StyledCenterBox>
            <View>
              <Typography.CardTitle colorName="text-dark-1" ellipsis>
                {shelfLabel?.title}
              </Typography.CardTitle>
              <Typography.BodyExtraSmall ellipsis colorName="text-dark-3" bold>
                {shelfLabel?.subTitle}
              </Typography.BodyExtraSmall>
            </View>
            <StyledValuesWrapper>
              <CardContentWrapper>
                <Typography.BodySmall colorName="text-dark-3">Sur l'étagère</Typography.BodySmall>
                <Switch
                  trackColor={{ false: '#767577', true: theme.colors['color-primary'] }}
                  onValueChange={() => onChangeShelfLabelSize(shelfLabelSize)}
                  value={shelfLabelSize === ShelfLabelSizeEnum.ShelLabelSmallSize}
                />
                <Typography.BodySmall colorName="text-dark-3">
                  Sur un support rayon
                </Typography.BodySmall>
              </CardContentWrapper>
            </StyledValuesWrapper>
          </StyledCenterBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default ShelfLabelSettingCard
