import { ProductEnums } from '../../../enums'
import { CartBatch } from '../Cart'
import { Order } from '../Order'

export type ShelfLabel = {
  id: string
  title?: string
  subTitle?: string
  displayPrice?: boolean
  customerPrice?: number
  clientPrice?: number
  mesureType?: ProductEnums.MESURE_TYPE
  itemImageId?: string
  storyImageId?: string
  storyTitle?: string
  storyText?: string
  rate?: HarvysRate
  order?: Order
  cartBatch?: CartBatch
}

export enum ShelfLabelSizeEnum {
  ShelfLabelFull = 1,
  ShelLabelSmallSize = 2,
}

export const SHELF_LABEL_SIZES = [
  {
    label: "Sur l'étagère",
    value: ShelfLabelSizeEnum.ShelfLabelFull,
  },
  {
    label: 'Sur un support rayon',
    value: ShelfLabelSizeEnum.ShelLabelSmallSize,
  },
]

export type UpdateShelfLabel = {
  customerPrice?: number
}

interface HarvysRate {
  overallScore?: number
  distributionChannel?: number
  productionType?: number
  origin?: number
  seasonality?: number
  storage?: number
}
