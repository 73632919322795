import { RouteProp, useRoute } from '@react-navigation/native'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { Switch } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import { Button, PageTitle, SectionTitle, Spacer } from '../../../components'
import Loader from '../../../components/Loader'
import { Order, Pagination } from '../../../domain'
import { ClientStackParamList } from '../../../navigation/ClientNavigationStack/ClientNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import OrderClientService from '../../../services/client/order'
import CardSettings from '../../../components/CardSettings'
import { ShelfLabel, ShelfLabelSizeEnum } from '../../../domain/ShelfLabel'
import ShelfLabelClientService from '../../../services/client/shelfLabel'
import CardListPaginated from '../../../components/CardListPaginated'
import ShelfLabelSettingCard from '../../../components/ShelfLabelSettingCard'
import PdfUtils from '../../../utilities/utils/pdf'

const ShelfLabelPrintSettingsScreen = () => {
  const theme = useTheme()
  const route = useRoute<RouteProp<ClientStackParamList, 'ShelfLabelPrintSettings'>>()

  const [printBarcodeSummary, setPrintBarcodeSummary] = useState<boolean>(true)
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [smallSizeLabels, setSmallSizeLabels] = useState<Set<string>>(new Set())
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { orderId, shelfLabelIds = [], withPrices = true } = route.params ?? {}

  const { data: order, isInitialLoading: orderLoading } = useQuery<Order, Error>(
    ['c_order'],
    () => OrderClientService.getOneOrder(orderId),
    {
      keepPreviousData: false,
      enabled: orderId !== '',
    },
  )

  const { data: paginatedShelfLabels, isLoading: paginatedShelfLabelsLoading } = useQuery<
    { data: ShelfLabel[]; pagination: Pagination },
    Error
  >(
    ['c_shelf_labels_order', orderId, pageNumber, pageSize],
    () => ShelfLabelClientService.getAll(orderId, pageSize, pageNumber),
    {
      keepPreviousData: true,
    },
  )

  const displayShelfLabels = React.useMemo(() => {
    if (!paginatedShelfLabels?.data)
      return { data: [], pagination: paginatedShelfLabels?.pagination }

    const filteredData =
      shelfLabelIds.length > 0
        ? paginatedShelfLabels.data.filter((label) => shelfLabelIds.includes(label.id))
        : paginatedShelfLabels.data

    return {
      data: filteredData,
      pagination: paginatedShelfLabels.pagination,
    }
  }, [paginatedShelfLabels, shelfLabelIds])

  const handleDisplayModeChange = (labelId: string, currentMode: ShelfLabelSizeEnum) => {
    setSmallSizeLabels((prev) => {
      const newSet = new Set(prev)
      if (currentMode === ShelfLabelSizeEnum.ShelfLabelFull) {
        newSet.add(labelId)
      } else {
        newSet.delete(labelId)
      }
      return newSet
    })
  }

  const onPressPrint = async () => {
    setIsLoading(true)
    try {
      const smallSizeIds = Array.from(smallSizeLabels)

      const base64Response = await ShelfLabelClientService.getPdf(
        orderId,
        withPrices,
        printBarcodeSummary,
        shelfLabelIds, // If empty, backend will use all labels from the order
        smallSizeIds, // Backend will consider all non-small labels as full size
      )

      if (!PdfUtils.isValidBase64Pdf(base64Response)) {
        throw new Error("Le PDF reçu n'est pas valide")
      }
      PdfUtils.downloadPdf(base64Response, `order_${orderId}.pdf`)
    } finally {
      setIsLoading(false)
    }
  }

  if (orderLoading || !order) {
    return <Loader isLoading pageLoading paddingLeft />
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper alignCenter>
        <PageTitle title="Format d'impression" />
        <SectionTitle title="Choisissez le rendu d'impression des étiquettes produits adapté à votre PLV" />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <CardSettings
          title="Ajouter le tableau d'aide à la saisie caisse"
          description="Au début du document vous aurez un tableau des produits avec leurs code-barres."
        >
          <Switch
            trackColor={{ false: '#767577', true: theme.colors['color-primary'] }}
            onValueChange={() => setPrintBarcodeSummary(!printBarcodeSummary)}
            value={printBarcodeSummary}
          />
        </CardSettings>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

        <SectionTitle title="Choisir l'emplacement des produits" />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

        <CardListPaginated
          data={displayShelfLabels.data}
          pagination={displayShelfLabels.pagination}
          element={(shelfLabel: ShelfLabel) => (
            <ShelfLabelSettingCard
              key={shelfLabel.id}
              shelfLabel={shelfLabel}
              shelfLabelSize={
                smallSizeLabels.has(shelfLabel.id)
                  ? ShelfLabelSizeEnum.ShelLabelSmallSize
                  : ShelfLabelSizeEnum.ShelfLabelFull
              }
              onChangeShelfLabelSize={(mode) => handleDisplayModeChange(shelfLabel.id, mode)}
            />
          )}
          isLoading={paginatedShelfLabelsLoading}
          emptyMessage="Aucune étiquette disponible"
          onChangePage={setPageNumber}
          hidePagination={shelfLabelIds?.length === 1}
        />

        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
        <Button
          label="Imprimer"
          onPress={onPressPrint}
          loading={isLoading}
          disabled={!displayShelfLabels.data || displayShelfLabels.data.length < 1}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ShelfLabelPrintSettingsScreen
